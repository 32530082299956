import React from 'react'
import { graphql } from 'gatsby'

import { Post } from '../lib/types'

import './styles/post-template.css'

import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Tag from '../components/tag'

interface Props {
  data: {
    markdownRemark: Post
  }
}

const BlogPostTemplate: React.FC<Props> = ({ data }) => {

  const post = data.markdownRemark
  const { title, date, tags, cover } = post.frontmatter

  return (
    <>
      <SEO title={post.frontmatter.title} />
      <Layout>

        <div className="t-post-template">

          {cover && <Img className="t-post-template__cover" fluid={(cover as any).childImageSharp.fluid} alt={title} />}

          {/* Title */}
          <div className="t-post-template__header">
            <h1>
              {title}
              <time>{date}</time>
            </h1>
            <div className="t-post-template__header__tags">{tags.map(tag => <Tag>{tag}</Tag>)}</div>
          </div>

          {/* Post html */}
          <div className="t-post-template__body" dangerouslySetInnerHTML={{ __html: post.html }} />

        </div>

      </Layout>
    </>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        tags
        cover {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`